const CONTEXT_PAGE_TYPES = {
  'article': 'buzz',
  'brand': 'feed',
  'contest': 'form',
  'compilation': 'compilation',
  'feature': 'feed',
  'mycart': 'mycart',
  'guides': 'feed',
  'home': 'feed',
  'in-app-only': 'landing',
  'ingredient': 'feed',
  'ingredients': 'feed', // ingredients landing page
  'lidl-fiver-feast': 'partner',
  'latest': 'feed',
  'recipe': 'recipe',
  'recipe-buy': 'recipe_buy',
  'search': 'feed',
  'submit-recipe': 'form',
  'tag': 'feed',
  'topic': 'feed',
  'accessibility': 'landing',
  'tastyvalues': 'landing',
  'my-meal-plan-my-way': 'partner',
  'zelle-savor-the-city': 'partner',
  'summer-of-beans': 'partner',
};

/**
 * @typedef {Object} analyticsInfo
 * @property {string} author - A comma separated list of author names
 * @property {string} author_id - A comma separated list of authors emails (used as a unique identifier for an author)
 * @property {string} content_id - ID of recipe or compilation
 * @property {string} content_name - A name to identify the page content (usually the url slug)
 * @property {string} context_page_id - (CET) Unique identifier for content hierarchy 0
 * @property {string} context_page_type - (CET) Page type for content hierarchy 0
 * @property {string} count - Search results total matched items
 * @property {string} e2e_test - For tagging tracking end to end tests
 * @property {string} page - Page type (recipe, compilation, search, assistant, etc...)
 * @property {string} text - JSON escaped Search string (which means string contains wrapping quotes).
 */
/**
 * @return {analyticsInfo}
 */
export const formatAnalyticsInfo = (pageType, { context_page_id = '', id = '', name = '', slug = '', ...content }) => {
  const has_video = (pageType === 'compilation')
    || (pageType === 'recipe' && content.non_video_recipe !== undefined && !content.non_video_recipe.status);
  const is_community = (pageType === 'recipe' && 'is_community' in content) ? content.is_community : false;

  // determine author info, which varies based on page type
  let author = '';
  let author_id = '';
  if (content.credits) {
    if (content.credits.names) {
      author = content.credits.names.join(',');
    }
    if (content.credits.emails) {
      author_id = content.credits.emails.join(',');
    }
  } else if (pageType === 'article') {
    author = content.username;
    author_id = content.user_id;
  }

  return {
    author,
    author_id,
    content_id: `${id}`,
    content_name: pageType === 'article' ? content.uri : slug,
    /**
      * if context_page_id is explicitly defined, use that (e.g. for feed pages), otherwise
      * use the id of the content (e.g. for recipe/compilation pages)
      */
    context_page_id: context_page_id || id,
    context_page_type: CONTEXT_PAGE_TYPES[pageType],
    count: '', // Any page type with feed data has the option of providing a count value
    description: content.description || '',
    e2e_test: '',
    has_video,
    is_community,
    page: pageType,
    page_edition: pageType === 'article' ? content.page_edition : 'en-us',
    text: '', // generally used for query text for search/topic
    title: content.title || name,
  };
};

/**
 * @typedef {string}
 */
/**
 * @return {string} - removes punctuation, turns spaces to underscores, and makes lowercase
 */
export const formatNameCET = (name) => {
  //eslint-disable-next-line no-useless-escape
  return name.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'?]/g, '').replace(/\s/g, '_').toLowerCase();
};
